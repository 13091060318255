<template>
  <div class="giveService">
    <div class="body">
      <div class="top">
        <div class="text">我们的服务</div>
      </div>
      <div class="middle">
        <div class="ul">
          <div class="li" v-for="(item, index) in ulLists" :key="index">
            <div class="img controller">
              <div>
                <img :src="item.ulr" alt="">
              </div>
            </div>
            <div class="text">{{ item.text +(item.num?' ('+item.num+')':'') }}</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <dv-border-box-8 style="height: 170px;padding-top: 4px;" :dur="10" :color="['#12429B', '#4487FF']">
          <div style="width: 100%;height: 100%;">
            <div class="ul">
              <div class="li" v-for="(item, index) in ul" :key="index">{{ item }}</div>
            </div>
            <div id="parent" class="parent" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
              <div id="child1" class="child">
                <div class="boxin" :key="index" :class="index == inx ? 'active' : ''" @click="inx = index"
                  v-for=" (item, index) in  dataList">
                  <div>{{ item.villageName }}</div>
                  <div style="color: #C12744;">{{ item.area1 }}</div>
                  <div>{{ item.area2 }}</div>
                  <div>{{ item.area3 }}</div>
                  <div style="color: #08BB45;">{{ item.rea4 }}</div>
                  <div class="complete">
                    <li class="Progress"> <span class="span1" :style="{ 'width': item.rea5 + '%' }"></span><span
                        class="span2"></span> </li>
                    <li class="percentage">{{ item.rea5 + '%' }}</li>
                  </div>
                </div>
              </div>
              <div id="child2" class="child"></div>

            </div>
          </div>
        </dv-border-box-8>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
        id: {
            type: Number,
            default: 207
        },
        mydata: []
  },
  data() {
    return {
      config: {},
      dataList: [
        { villageName: '报修', area1: 180, area2: 180, area3: 180, rea4: 180, rea5: 70, },
        { villageName: '投诉', area1: 180, area2: 180, area3: 180, rea4: 180, rea5: 60, },
        { villageName: '求助', area1: 180, area2: 180, area3: 180, rea4: 180, rea5: 50, },
        { villageName: '咨询', area1: 180, area2: 180, area3: 180, rea4: 180, rea5: 10, },
      ],
      inx: 1,
      ul: ['服务名称', '待受理', "已接单", "受理中", '已解决', '完成率'],
      timer: undefined,
      ulList: [
        {
          ulr: "https://f.jsttwy.cn/public/base1.png",
          text: '报修'
        }, {
          ulr: "https://f.jsttwy.cn/public/base4.png",
          text: '咨询'
        }, {
          ulr: "https://f.jsttwy.cn/public/base2.png",
          text: '投诉'
        }, {
          ulr: "https://f.jsttwy.cn/public/base3.png",
          text: '求助'
        }
      ],
      ulLists:[]
    }
  },
  mounted() {
    this.scrolls()
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData(){
      this.dataList = this.mydata.map(item=>{
        return {
          villageName: item.type,
          area1: item.orderNew,
          area2: item.received,
          area3: item.accepted,
          rea4: item.finished,
          rea5: parseInt(item.finishRate * 100),
        }
      })
      this.ulLists =this.ulList.map((item,index)=>{
        let num=undefined
        if(this.mydata[index]){
          num =this.mydata[index].finished+'/'+this.mydata[index].total
        }
        return {
          ulr: item.ulr,
          text: item.text,
          num:num
        }
      })
    },
    scrolls() {
      var parent = document.getElementById("parent");
      var child1 = document.getElementById("child1");
      var child2 = document.getElementById("child2");
      // console.log(parent);
      if (child1.clientHeight >= parent.clientHeight) child2.innerHTML = child1.innerHTML;
      this.timer = setInterval(function () {
        if (parent.scrollTop >= child1.scrollHeight) {
          parent.scrollTop = 0;
        } else {
          parent.scrollTop += 1
        }
      }, 30);

    },
    handleMouseEnter() {
      clearInterval(this.timer);
    },
    handleMouseLeave() {
      this.scrolls();
    }

  }
}
</script>

<style lang="scss" scoped>
.giveService {
  width: 100%;
  height: 450px;
  padding: 20px 30px 20px;
  box-sizing: border-box;

  .body {
    width: 100%;
    height: 100%;

    .top {
      .text {
        width: 193px;
        height: 30px;
        display: flex;
        align-items: center;
        padding-left: 40px;
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
        background-image: url("https://f.jsttwy.cn/public/Title.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

      }
    }

  }

  .middle {
    width: 100%;
    height: 180px;

    .ul {
      display: flex;

      .li {
        padding-top: 4px;

        .img {
          width: 100%;
          height: 150px;
          display: flex;
          position: relative;

          div {
            position: absolute;
            top: 20px;
            left: 40px;

            z-index: 100;
            -webkit-animation: bounce-down 3s linear infinite;
            animation: bounce-down 3s linear infinite;

            img {
              position: absolute;
              width: 110px;
            }
          }



          @-webkit-keyframes bounce-down {
            25% {
              -webkit-transform: translateY(-4px);
            }

            50%,
            100% {
              -webkit-transform: translateY(0);
            }

            75% {
              -webkit-transform: translateY(4px);
            }
          }

          @keyframes bounce-down {
            25% {
              transform: translateY(-4px);
            }

            50%,
            100% {
              transform: translateY(0);
            }

            75% {
              transform: translateY(4px);
            }
          }
        }

        flex: 1;

        .text {
          width: 100%;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(to right, rgba(255, 255, 255, 0.01) 20%, #0849A5 50%, rgba(255, 255, 255, 0.01) 80%);
          ;
        }
      }
    }
  }

  .bottom {
    margin-top: 20px;

    .ul {
      width: 99%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      background: linear-gradient(to top, #04316E, #172648);

      .li {
        flex: 1;
        color: #0078FF;
        text-align: center;
      }
    }

  }
}

.parent {
  margin: auto;
  font-size: 23px;
  box-sizing: border-box;
  width: 100%;
  height: 122px;
  overflow: hidden;

  // .child f// height: 100%;
  .boxin {
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    div {
      flex: 1;
      height: 42px;
      line-height: 42px;
      border: none;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: rgba(18, 52, 116, 0.3);
    }

    .complete {

      display: flex;
      align-items: center;
      justify-content: center;

      .Progress {
        width: 70px;
        height: 5px;
        border-radius: 3px;
        background-color: #425375;
        display: flex;
        align-items: center;

        .span1 {
          display: inline-block;
          height: 100%;
          border-radius: 3px;
          background-color: #00F0FF;
        }

        .span2 {
          width: 7px;
          height: 7px;
          background-color: #06DEFF;
          border-radius: 50%;
          position: relative;
          left: -4px;
          box-shadow: 0 0 8px 1px #06DEFF;

        }
      }

      .percentage {
        width: 40px;
        font-size: 14px;
        margin-right: 10px;
      }
    }

  }


}
</style>
