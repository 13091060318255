<template>
    <div class="Histogram">
        <div class="top">
            <div class="left">工单完成率</div>
            <div class="data">
                <div :class="{ 'box': tab == 1 }" @click="tab = 1; loadData()">本月</div>
                <div :class="{ 'box': tab == 2 }" @click="tab = 2; loadData()">本年</div>
            </div>
        </div>
        <div id="main" style="width: 100%;height:220px;"></div>
    </div>
</template>
  
<script>
import * as echarts from 'echarts';
import { orderCompletionRate } from '@/api/bs'
import { getRandomIntInclusive } from '@/utils/index.js'
export default {
    data() {
        return {
            tab: 1,
            mydata: {}
        }
    },
    props: {
        id: {
            type: Number,
            default: 207
        }
    },
    mounted() {
        this.loadData()
        setInterval(() => {
            this.switchTab()
        }, getRandomIntInclusive(6, 15) * 1000);
    },
    methods: {
        switchTab() {
            this.tab = this.tab === 1 ? 2 : 1
            this.loadData()
        },

        async loadData() {
            const res = await orderCompletionRate({ deptId: this.id, date: this.tab === 1 ? 'month' : 'year' })
            if (res.code === 200) {
                this.mydata = res.data
              
                this.GetEcharts()
            }
        },

        GetEcharts() {
            let option = {
                数据节点1: 30,
                数据节点2: 52,
                color: ['rgba(7,111,216,', 'rgba(7,111,216,', 'rgba(7,111,216,'],
                dataset: {
                    source: [
                        ["product", "data"],
                        ...Object.entries(this.mydata).map(item => {
                            if (item[1]==0) {
                                item[1]=0.009
                            }
                            return [item[0], item[1] * 100]
                        })
                        // ["巡检管理", "10"],
                        // ["设备维保", "50"],
                        // ["卫生保洁", "25"],
                        // ["环境绿化", "60"],
                        // ["保安服务", "77"],
                    ]
                },
                grid: {
                    top: '20%',
                    left: '8%',
                    right: '4%',
                    bottom: '16%',
                },
                "tooltip": {
                    "trigger": "axis",
                    "axisPointer": {
                        "type": "line",
                        "lineStyle": {
                            "width": 60,
                            "color": "rgba(7,111,216,.1)"
                        }
                    },
                    "legend": {
                        "show": true,
                        "right": 10,
                        "data": [
                            "有销库存满足率"
                        ],
                        "itemWidth": 4,
                        "itemHeight": 12,
                        "itemGap": 15,
                        "icon": "circle",
                        "textStyle": {
                            "color": "rgba(7,111,216,1)",
                            "fontSize": 12
                        }
                    },
                    "borderWidth": 1,
                    "backgroundColor": "#0e2b33",
                    "borderColor": "rgba(7,111,216, 1)",
                    "formatter": function (A) {
                        var htm = "<div style='color:#fff'>" + A[0].name + "</div><div style='color:rgba(14, 248, 254,1)'>" + A[0].name + "完成率：" + A[0].value[1].toLocaleString() + "%</div><div style='color:rgba(14, 248, 254,1)'>"
                        return htm
                    },
                    "extraCssText": "z-index:96"
                },
                xAxis: {
                    type: "category",
                    axisLabel: {
                        show: true,
                        margin: 20,
                        align: 'center',
                        textStyle: {
                            color: '#999999',
                            fontSize: 12
                        }
                    }
                },
                yAxis: {
                    type: "value",
                    min: 0,
                    max: 100,
                    axisLabel: {
                        show: true,
                        margin: 20,
                        align: 'center',
                        formatter: '{value}%',
                        textStyle: {
                            fontSize: 12,
                            color: '#999999',

                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: '#34373F' // 设置 y 坐标轴线颜色为浅灰色
                        }
                    },
                    axisLine: {
                        show: false,
                    },
                },
                series: [{
                    name: '完成率', //顶
                    type: 'pictorialBar',
                    symbolSize: [25, 10],
                    symbolOffset: [0, -3],
                    symbolPosition: 'end',
                    z: 12,
                    itemStyle: {
                        color: function () {
                            return option.color[2] + "1)"
                        },
                        barBorderRadius: 30,
                    },
                    label: {
                        show: true,
                        position: "top",
                        fontSize: 15,
                        fontFamily: "DIN",
                        fontWeight: "bold",
                        formatter: function (params) {
                            if(params.value[params.encode.y[0]]<1){
                                params.value[params.encode.y[0]]=0
                            }
                            return Number(params.value[params.encode.y[0]].toFixed(2)) + "%"
                        }
                    }
                }, {
                    name: '完成率', //底
                    type: 'pictorialBar',
                    symbolSize: [25, 10],
                    z: 12,
                    itemStyle: {
                        color: function () {
                            return option.color[2] + "1)"
                        },
                        barBorderRadius: 30
                    }
                }, {
                    name: '完成率', //底
                    type: 'pictorialBar',
                    symbolSize: [50, 25],
                    symbolOffset: [0, 12],
                    itemStyle: {
                        normal: {
                            color: 'transparent',
                            borderColor: "rgba(7,111,216,1)",
                            borderWidth: 1
                        }
                    }
                }, {
                    name: '完成率', //底
                    type: 'pictorialBar',
                    symbolSize: [35, 17],
                    symbolOffset: [0, 8],
                    itemStyle: {
                        normal: {
                            color: 'transparent',
                            borderColor: "rgba(7,111,216,1)",
                            borderWidth: 1
                        }
                    }
                }, {
                    name: '日环比',
                    type: 'bar',
                    itemStyle: {
                        color: function (params) {
                            var colorList = []
                            colorList[params.dataIndex] = new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [{
                                    offset: 0,
                                    color: option.color[2] + '1)'
                                },
                                {
                                    offset: 1,
                                    color: option.color[2] + '0.0001)'
                                }
                                ]
                            )
                            return colorList[params.dataIndex]
                        }
                    },
                    showBackground: true,
                    backgroundStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [{
                                offset: 0,
                                color: 'rgba(14, 248, 254,0)'
                            }, //柱图渐变色
                            {
                                offset: 1,
                                color: 'rgba(14, 248, 254,0.1)'
                            }
                            ]
                        )
                    },
                    barWidth: 25,
                    barGap: '-100%'
                },
                ]
            };

            var myChart = echarts.init(document.getElementById('main'));
            // 绘制图表
            myChart.setOption(option);
        }
    }
}
</script>
  
<style lang="scss" scoped>
.Histogram {
    padding: 20px 20px;
    box-sizing: border-box;

    .top {
        padding: 0 20px;
        height: 40px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;

        .left {
            width: 193px;
            height: 30px;
            color: #fff;
            font-weight: 600;
            display: flex;
            align-items: center;
            font-size: 18px;
            padding-left: 40px;
            // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
            background-image: url("https://f.jsttwy.cn/public/Title.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;

        }

        .data {
            display: flex;
            color: #ccc;

            div {
                width: 60px;
                height: 30px;
                border: 1px solid #0070C2;
                text-align: center;
                line-height: 30px;
                font-size: 16px;
                margin: 0;
            }

            .box {
                color: #00FEFF;
                background-image: url("https://f.jsttwy.cn/public/box5.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

        }
    }
}
</style>
  