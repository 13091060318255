<template>
    <div class="work">
        <div class="body">
            <div class="top">
                <div class="text">今日工单</div>
            </div>
            <div>
                <div class="span">
                    <span
                        style="display: inline-block;width: 16px;height: 16px;background-color:rgba(57,127,255, 1);"></span>
                    <span style="margin-right: 14px;">已完成</span>
                    <span
                        style="display: inline-block;width: 16px;height: 16px;background-color: rgba(64, 91, 184, 0.5)"></span>
                    <span>全部</span>
                </div>
                <div id="mains" style="width: 100%;height:200px;"></div>
            </div>
        </div>
    </div>
</template>
  
<script>
import * as echarts from 'echarts';
export default {
    props: {
        id: {
            type: Number,
            default: 207
        },
        mydata: {}
    },
    data() {
        return {
            config: {},
        }
    },
    mounted() {
        this.getWork()
    },
    created() {
    },
    methods: {
        getWork() {
            let ydata = this.mydata.orderType
            let data1 = this.mydata.total
            let data2 = this.mydata.finished
            let option = {
                xAxis: {
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        textStyle: {
                            color: '#fff',
                        },
                    },
                    formatter: function (params) {
                        return params[0].name + '<br>'
                            + params[1].marker + '' + params[1].seriesName + '：' + params[1].value + '<br>'
                            + params[0].marker + '' + params[0].seriesName + '：' + + params[0].value + '\n'
                    }
                },
                grid: {
                    left: "14%",
                    right: "26%",
                    bottom: "10%",
                    top: "10%",
                    containLabel: false
                },
                yAxis: [
                    {
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            color: '#999999',
                            fontSize: 14,
                        },
                        position: 'left',
                        data: ydata,
                    },
                ],
                series: [
                    {
                        // 背景样式
                        name: '全部',
                        type: "pictorialBar",
                        barWidth: 14,
                        // barHeight: 10,
                        stack: '总量',
                        barGap: '-100%',
                        symbol: 'fixed',
                        legendHoverLink: false,
                        itemStyle: {
                            normal: {
                                color: 'rgba(64, 91, 184, 0.3)'
                            },

                        },
                        label: {
                            show: true,
                            position: 'insideLeft',
                            offset: [280, 0],
                            formatter: (p) => {
                                let index = -1;
                                for (let i = 0; i < ydata.length; i++) {
                                    if (ydata[i] == p.name) {
                                        index = i;
                                    }
                                }
                                return `{a|${data2[index]}}{c| / }{b|${data1[index]} }`
                            },
                            rich: {
                                a: {
                                    color: '#07D1FA',
                                    fontSize: 15,
                                },
                                b: {
                                    color: '#B0DEF5',
                                    fontSize: 16,
                                },
                                c: {
                                    color: '#fff',
                                    fontSize: 15,
                                },
                            }
                        },
                        data: data1,
                        animation: false, //关闭动画
                        z: 0
                    },
                    {
                        name: '已完成',
                        type: "pictorialBar",
                        barWidth: 14,
                        legendHoverLink: false,
                        itemStyle: { //lenged文本
                            opacity: 1,
                            color: function () {
                                return new echarts.graphic.LinearGradient(0, 1, 1, 0, [{
                                    offset: 0,
                                    color: 'rgba(57,127,255, 0.6)' // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: 'rgba(57,127,255, 1)' // 100% 处的颜色
                                }], false)
                            }
                        },
                        silent: true,
                        symbol: 'rect',
                        symbolClip: true,
                        symbolPosition: 'start',
                        symbolOffset: [0, 0],
                        data: data2,
                        z: 1,
                    },
                ]
            }
            var myChart = echarts.init(document.getElementById('mains'));
            // 绘制图表
            myChart.setOption(option);
        }

    }
}
</script>
  
<style lang="scss" scoped>
.work {
    width: 100%;
    height: 450px;
    padding: 30px 20px 20px;
    box-sizing: border-box;

    .body {
        width: 100%;
        height: 100%;

        .top {
            .text {
                width: 193px;
                height: 30px;
                display: flex;
                align-items: center;
                padding-left: 40px;
                font-size: 18px;
                color: #fff;
                font-weight: 600;
                // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
                background-image: url("https://f.jsttwy.cn/public/Title.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }

        .span {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                margin-left: 6px;
            }

            position: relative;
            top: 8px;
        }

    }


}
</style>
  