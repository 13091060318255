<template>
    <el-dialog title="选择页面" :visible.sync="pagesDialogVisible" width="700px" append-to-body>
      <el-table :data="pagesData">
        <el-table-column property="community" label="项目" width="150" />
        <el-table-column label="向业主公开展示" width="150">
          <template slot-scope="scope">
            <el-link v-if="scope.row.type===1" href="/#/home2" target="_top" type="info" icon="el-icon-link">{{scope.row.community}}</el-link>
            <el-link v-else :href="'/#/?id='+scope.row.id" target="_top" type="primary" icon="el-icon-link">{{scope.row.community}}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="物业内部管理" width="150">
          <template slot-scope="scope">
            <el-link v-if="scope.row.type!==1" :href="'/#/home3?id='+scope.row.id" type="danger" icon="el-icon-brush">{{scope.row.community}}</el-link>
          </template>
        </el-table-column>
        <el-table-column property="address" label="地区" />
      </el-table>
    </el-dialog>
</template>

<script>
export default {
  name: 'PagesDialog',
  data () {
    return {
        pagesDialogVisible:false,//显示选择页面对话框
        pagesData: [
            { community: '全国', type: 1 },
            { community: '蓝天水岸', id: 207, address: '江苏 南京' },
            { community: '蓝天慧融', id: 222, address: '江苏 南京' },
            { community: '名人苑', id: 293, address: '山东 聊城' }
        ],
    }
  },
  methods: {
    open(){
      this.pagesDialogVisible=true
    },
    close(){
      this.pagesDialogVisible=false
    },
  }
}
</script>

<style>

</style>
