<template>
    <el-carousel height="297px" :interval="8000">
      <el-carousel-item v-for="(item,index) in mydata" :key="index">
        <img :src="item" class="bs-img">
      </el-carousel-item>
    </el-carousel>
</template>
  
<script>
export default {
    data() {
        return {
            
        }
    },
    props: {
        id: {
            type: Number,
            default: 207
        },
        mydata: []
    },
    mounted() {
        // this.loadData()
    },
    created() {

    },
    methods: {
        
    }
}
</script>
  
<style scoped>
.bs-img{
  width: 501px;
  height: 287px;
  border-radius: 20px;
  margin: 6px 0 0 10px;
  object-fit: cover;
}
</style>
  