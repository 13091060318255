<template>
    <div class="team">
        <div class="body">
            <div class="top"><div class="text">服务团队</div></div>
            <div class="bottom" id="bottom">
                <div class="team-box" id="teamBox" @mouseenter="handleMouseEnter" @mouseleave="scrolls">
                    <div class="ul" id="ul" ref='ul'>
                        <div class="li" v-for="(item, index) in team" :key="index">
                            <div class="img">
                                <div class="img1">
                                    <div class="image">
                                        <img :src="item.avatar || '/no-avatar.png'" alt="" >
                                    </div>
                                </div>
                                <div class="img2"> <img src="https://f.jsttwy.cn/public/arrow.png" alt=""></div>
                            </div>
                            <div class="Text">
                                <div><span class="text">姓名:</span><span class="text1">{{ item.userName }}</span></div>
                                <div><span class="text">部门:</span><span>{{ item.deptName }}</span></div>
                                <div><span class="text">电话:</span><span>{{ item.phone }}</span></div>
                            </div>
                        </div>
                    </div>
                    <div id="ul2" class="ul"></div>
                </div>
            </div>
            <div class="top"><div class="text">今日值班</div></div>
            <div class="number" id="number">
                <div class="today-box" id="todayBox" @mouseenter="handleMouseEnters" @mouseleave="scrolls1">
                    <div class="ulNumber" id="ulNumber">
                        <div class="liNumber" v-for="(item, index) in today" :key="index">
                            <div class="img">
                                <div style="width: 58px;height: 58px;overflow: hidden;border-radius: 100%;border: 0.5px solid #1692CB;">
                                    <img :src="item.avatar || '/no-avatar.png'" alt="" style="width:100%;height:100%;object-fit:cover;">
                                </div>
                            </div>
                            <div class="numberText">
                                <div class="name"><span class="name1">姓名：</span><span class="name2">{{ item.userName }}</span></div>
                                <div class="num">
                                    <span class="num1">手机号：</span>
                                    <span class="num2" v-for="(items, index) in item.phone" :key="index">{{ items }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ulNumber1 ulNumber" id="ulNumber1"></div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import {
    getServiceTeam
} from '@/api/bs'
export default {
    props: {
        id: {
            type: Number,
            default: 207
        },
    },
    data() {
        return {
            config: {},
            team: [],
            today: [],
            inx: 0,
            index: 0,
            time1: undefined,
            time2: undefined
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        async loadData() {
            const res = await getServiceTeam({ deptId: this.id })
            if (res.code === 200) {
                this.team = res.data.serviceTeam
                this.today = res.data.todayDuty
                setTimeout(() => {
                    this.scrolls()
                    this.scrolls1()
                }, 100)
            }
        },
        // 开始滚动
        scrolls() {
            var ul = document.getElementById("ul");
            var ul2 = document.getElementById("ul2");
            var box = document.getElementById("teamBox");
            let width = (170 * this.team.length)
            if (this.team && this.team.length && width >= box.clientWidth) {
                ul2.innerHTML = ul.innerHTML;
                box.style.transform = 'translateX(' + this.inx + 'px)';
                this.time1 = setInterval(() => {
                    if ((this.inx + width) < 0) {
                        this.inx = 0
                    }
                    this.inx--
                    box.style.transform = 'translateX(' + this.inx + 'px)';
                }, 20);
            }
        },
        // 清除滚动
        handleMouseEnter() {
            clearInterval(this.time1);
        },

        // 第二个开始滚动
        scrolls1() {
            var ulNumber = document.getElementById("ulNumber");
            var ulNumber1 = document.getElementById("ulNumber1");
            var box = document.getElementById("todayBox");
            let width = (519 * this.today.length)
            if (this.team && this.team.length && width >= box.clientWidth) {
                ulNumber1.innerHTML = ulNumber.innerHTML;
                box.style.transform = 'translateX(' + this.index + 'px)';
                this.time2 = setInterval(() => {
                    if ((this.index + width) < 0) {
                        this.index = 0
                    }
                    this.index--
                    box.style.transform = 'translateX(' + this.index + 'px)';
                }, 20);
            }

        },
        // 清除滚动
        handleMouseEnters() {
            clearInterval(this.time2);
        },

    }
}
</script>
  
<style lang="scss" scoped>
.team {
    width: 100%;
    height: 450px;
    padding: 30px 20px 20px;
    box-sizing: border-box;

    .body {
        width: 100%;
        height: 100%;

        .top {
            .text {
                width: 193px;
                height: 30px;
                display: flex;
                align-items: center;
                padding-left: 40px;
                font-size: 18px;
                color: #fff;
                font-weight: 600;
                // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
                background-image: url("https://f.jsttwy.cn/public/Title.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
    }

    .bottom {
        width: 100%;
        height: 200px;
        margin-top: 10px;
        overflow: hidden;

        .team-box {
            display: flex;

            .ul {
                width: 1000000px;
                display: flex;
                align-items: center;

                .li {
                    width: 170px;
                    height: 100%;
                    cursor: pointer;

                    .img {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;


                        .img1 {
                            width: 90px;
                            height: 110px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-image: url("https://f.jsttwy.cn/public/box3.png");
                            background-repeat: no-repeat;
                            background-size: 100% 100%;

                            .image {
                                width: 80px;
                                height: 94px;
                                background-color: #00FEFF;
                                img {
                                    width: 80px;
                                    height: 94px;
                                    border-radius: 6px;
                                    overflow: hidden;
                                }
                            }


                        }

                        .img2 {
                            display: flex;
                            align-items: center;
                            justify-content: end;
                            padding-right: 10px;
                            box-sizing: border-box;


                            img {
                                width: 35px;
                                height: 31px;

                            }
                        }
                    }

                    .Text {
                        div {
                            margin-top: 6px;
                            color: #CCCCCC;
                            display: flex;

                            .text {

                                font-size: 14px;
                                margin-right: 4px;
                            }

                            .text1 {
                                width: 70%;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                -o-text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
        }
    }

    .number {
        width: 100%;
        height: 120px;
        padding: 4px 0 0;
        box-sizing: border-box;
        overflow: hidden;
        margin-top: 8px;

        .today-box {
            height: 100%;
            display: flex;

            .ulNumber {
                height: 100%;
                display: flex;

                .liNumber {
                    height: 100%;
                    width: 485px;
                    display: flex;
                    margin-right: 34px;

                    .img {
                        width: 84px;
                        height: 80px;
                        margin-left: 12px;
                        background-image: url('https://f.jsttwy.cn/public/headsculpture.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        position: relative;
                        z-index: 2;
                        padding: 10px 0 0 13px;
                        box-sizing: border-box;
                    }

                    .numberText {
                        width: 390px;
                        height: 100%;
                        position: relative;
                        left: -1px;
                        // top: 0px;
                        background-image: url('https://f.jsttwy.cn/public/box4.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        padding: 14px 0 0 20px;
                        box-sizing: border-box;

                        span {
                            display: inline-block;
                        }

                        .name {
                            color: #CCCCCC;
                            margin-bottom: 18px;

                            .name1 {
                                width: 64px;
                            }

                            .name2 {
                                color: #00FEFF;
                                font-size: 18px;
                            }
                        }

                        .num {
                            height: 36px;
                            display: flex;
                            align-items: center;

                            .num1 {
                                width: 64px;
                            }

                            .num2 {
                                width: 26px;
                                height: 36px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #00FEFF;
                                background-image: url("https://f.jsttwy.cn/public/phone.png");
                                background-size: 100% 100%;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
  