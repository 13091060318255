<template>
    <div class="videos">
        <div class="body">
            <div class="top">
                <div class="text">工作展示</div>

            </div>
            <div class="bottom">
                <div class="data">
                    <div :class="{ 'box': tab == 1 }" @click="goto(1)">巡检</div>
                    <div :class="{ 'box': tab == 2 }" @click="goto(2)">维保</div>
                    <div :class="{ 'box': tab == 3 }" @click="goto(3)">保洁</div>
                    <div :class="{ 'box': tab == 4 }" @click="goto(4)">绿化</div>
                    <div :class="{ 'box': tab == 5 }" @click="goto(5)">安保</div>
                    <img :src="'https://f.jsttwy.cn/public/daily/'+id+'.jpg'">
                </div>
                <div class="bottoms" v-if="mydata.length">
                    <el-carousel :interval="2000" indicator-position="none" type="card" height="260px" style="width: 100%;">
                        <el-carousel-item v-for="item in mydata" :key="item">
                            <el-image style="height: 260px;width: 100%; " :src="item" fit="cover" :preview-src-list="mydata">
                            </el-image>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>

        </div>
    </div>
</template>
  
<script>
import { CoreServices } from '@/api/bs'
export default {
    data() {
        return {
            tab: 5,
            mydata: [],
            time: undefined

        }
    },
    props: {
        id: {
            type: Number,
            default: 207
        }
    },
    mounted() {
        this.loadData()
        this.switchTab()

    },
    created() {
    },
    methods: {
        switchTab() {
            if (this.tab === 5) {
                this.tab = 0
            }
            this.tab++
            this.loadData()
        },

        async loadData() {
            const res = await CoreServices({ deptId: this.id, type: this.tab })
            if (res.code === 200) {
                this.mydata = res.data
                this.time = setTimeout(() => {
                    this.switchTab()
                }, (this.mydata.length + 1) * 2000)

            }
        },
        goto(num) {
            this.tab = num;
            this.loadData();
            clearTimeout(this.time);
        }
    }
}
</script>
  
<style lang="scss" scoped>
.videos {
    width: 100%;
    height: 450px;
    padding: 20px 0 20px;
    box-sizing: border-box;

    .body {
        width: 100%;
        height: 100%;

        .top {
            padding: 0 20px;
            height: 32px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: space-between;

            .text {
                width: 193px;
                height: 30px;
                display: flex;
                align-items: center;
                padding-left: 40px;
                font-size: 18px;
                color: #fff;
                font-weight: 600;
                // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
                background-image: url("https://f.jsttwy.cn/public/Title.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
    }

    .bottom {
        width: 100%;
        height: 260px;
        padding: 0 20px 10px 40px;
        box-sizing: border-box;
        display: flex;

        .data {
            color: #ccc;
            margin-right: 16px;
            img{
                width: 72px;
                height: 72px;
            }

            div {
                width: 72px;
                height: 30px;
                border: 1px solid #0070C2;
                text-align: center;
                line-height: 30px;
                font-size: 16px;
                margin: 0 0 4px 0;
                cursor: pointer;
            }

            .box {
                color: #00FEFF;
                background-image: url("https://f.jsttwy.cn/public/box5.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

        }

        .bottoms {
            position: relative;
            // background-color: #00FEFF;
            top: -20px;
            width: 100%;
            height: 260px;
            padding: 0;
            box-sizing: border-box;
            display: flex;

            video {
                height: 100%;
            }

            ::v-deep .el-carousel__item .is-animating {
                transform: translateZ(0) !important;
            }

        }
    }

    .el-carousel__item div {
        color: #475669;
        font-size: 14px;
        opacity: 1;
        // 原先存在的样式
        // opacity: 0.75;
        margin: 0;
        text-align: center;
    }


    .el-carousel__item--card {
        background-color: rgba(40, 40, 40) !important;
    }





}
</style>
  