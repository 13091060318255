<template>
    <div class="task">
        <div class="body">
            <div class="top">
                <div class="text">管理检查记录</div>
            </div>
            <div class="bottom" v-if="dataList && dataList.length">
                <dv-border-box-8 style="height: 200px;padding-top: 4px;" :dur="10" :color="['#12429B', '#4487FF']">
                    <div style="width: 100%;height: 100%; padding:0 4px;  box-sizing: border-box;">
                        <div class="ul">
                            <div class="li" v-for="(item, index) in ul" :key="index">{{ item }}</div>
                        </div>
                        <div id="Parent" class="Parent" @mouseenter="handleMouseEnter" @mouseleave="scrolls">
                            <div id="Child1" class="child">
                                <div class="boxin" :key="index" :class="(index % 2) == 1 ? 'active' : 'active1'"
                                    v-for=" (item, index) in  dataList">
                                    <div>{{ item.villageName }}</div>
                                    <div>{{ item.area1 }}</div>
                                    <div>{{ item.area2 }}</div>
                                    <div :class="{ 'color1': item.area3 == '未开始', 'color2': item.area3 == '待分配', 'color3': item.area3 == '已受理', 'color4': item.area3 == '进行中', 'color5': item.area3 == '已完成' }">
                                        {{ item.area3 }}</div>
                                </div>
                            </div>
                            <div id="Child2" class="child"></div>

                        </div>
                    </div>
                </dv-border-box-8>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    props: {
        mydata: []
    },
    data() {
        return {
            config: {},
            dataList: [
                // { villageName: '张十七', area1: '巡检', area2: '2023-7-23 7:00-17:00', area3: '进行中', },
                // { villageName: '十七', area1: '维保', area2: 180, area3: '待分配', },
                // { villageName: '刘小卓', area1: '保洁', area2: 180, area3: '未开始', },
                // { villageName: '张全龙', area1: '巡检', area2: 180, area3: '已完成', },
                // { villageName: '张小龙', area1: '保洁', area2: 180, area3: '进行中', },
                // { villageName: '张大龙', area1: '安保', area2: 180, area3: '已受理', },
                // { villageName: '廖珊珊', area1: '客服', area2: 180, area3: '进行中', },
            ],
            inx: 1,
            ul: ['负责人', '工作内容', "时间", "状态"],
            timer: undefined
        }
    },
    mounted() {
        this.scrolls()
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData(){
            this.dataList = this.mydata.map(item=>{
                return {
                    villageName: item.userName,
                    area1: item.taskType,
                    area2: item.startTime,
                    area3: item.status
                }
            })
        },

        scrolls() {
            var Parent = document.getElementById("Parent");
            var Child1 = document.getElementById("Child1");
            var Child2 = document.getElementById("Child2");
            if (Child1.clientHeight >= Parent.clientHeight) Child2.innerHTML = Child1.innerHTML;
            this.timer = setInterval(function () {
                if (Parent.scrollTop >= Child1.scrollHeight) {
                    Parent.scrollTop = 0;
                } else {
                    Parent.scrollTop += 1
                }
            }, 30);

        },
        handleMouseEnter() {
            clearInterval(this.timer);
        },

    }
}
</script>
  
<style lang="scss" scoped>
.task {
    width: 100%;
    height: 450px;
    padding: 30px 20px 20px;
    box-sizing: border-box;

    .body {
        width: 100%;
        height: 100%;

        .top {
            .text {
                width: 193px;
                height: 30px;
                display: flex;
                align-items: center;
                padding-left: 40px;
                font-size: 18px;
                color: #fff;
                font-weight: 600;
                // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
                background-image: url("https://f.jsttwy.cn/public/Title.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;


            }
        }

    }



    .bottom {
        margin-top: 10px;


        .ul {
            width: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            background: linear-gradient(to top, rgba(14, 53, 131, 0.8), rgba(14, 53, 131, 0.4), rgba(14, 53, 131, 0.8));

            .li {
                flex: 1;
                color: #0078FF;
                text-align: center;
            }
        }

    }
}

.Parent {
    margin: auto;
    font-size: 23px;
    box-sizing: border-box;
    width: 100%;
    height: 153px;
    overflow: hidden;

    // .child f// height: 100%;
    .boxin {
        box-sizing: border-box;
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;

        div {
            flex: 1;
            height: 42px;
            line-height: 42px;
            border: none;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: rgba(18, 52, 116, 0.3);
        }

        .color1 {
            color: gray;
        }

        .color2 {
            color: #07CF34;
        }

        .color3 {
            color: #07CF34;
        }

        .color4 {
            color: #87CEEB;
        }

        .color5 {
            color: #07CF34;
        }
    }


}

#Child1 {
    .active {
        background: linear-gradient(to top, rgba(19, 47, 109, 0.8), rgba(19, 47, 109, 0.4), rgba(19, 47, 109, 0.8));

    }
}

#Child2 {
    .active1 {
        background: linear-gradient(to top, rgba(19, 47, 109, 0.8), rgba(19, 47, 109, 0.4), rgba(19, 47, 109, 0.8));

    }
}
</style>
  