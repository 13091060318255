<template>
  <div id="index" ref="appRef">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="top" @click="pagesDialogOpen">
          <div class="top-left">
            <span class="text1">{{ dateYear }}</span>
            <span class="text2">{{ dateDay }}</span>
          </div>
          <div class="title">腾泰翼管家</div>
          <div class="top-right">{{ projectName }}</div>
        </div>

        <div class="body">
          <!-- 第一列 -->
          <div class="left">
            <div class="box1">
              <intro v-if="noticeDisplay" :mydata="notice" :id="id" :key="11+componentKey" />
            </div>
            <div class="box2">
              <histogram :id="id" />
            </div>
            <div class="box3">
              <charge v-if="chargeItemDisplay" :mydata="chargeItem" :id="id" :key="13+componentKey" />
            </div>
          </div>
          <!-- 第二列 -->
          <div class="middle">
            <div class="box1">
              <giveService v-if="order.length" :mydata="order" :id="id" />
            </div>
            <div class="box2">
              <team :id="id" :key="22+componentKey" />
            </div>
          </div>

          <!-- 第三列 -->
          <div class="right">
            <div class="box1">
              <videos :id="id" />
            </div>
            <div class="box2">
              <work v-if="todayOrderDisplay" :mydata="todayOrder" :id="id" />
            </div>
            <div class="box3">
              <task v-if="taskCheck && taskCheck.length" :mydata="taskCheck" :key="33+componentKey" />
            </div>
          </div>
        </div>
      </div>
      <pages-dialog ref="pagesDialogRef" />
  </div>
</template>

<script>
import { getNotice, getOrderAndTask } from "@/api/bs";
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
import PagesDialog from "@/components/PagesDialog.vue";
import giveService from "./Home1/giveService";
import histogram from "./Home1/histogram";
// import publicity from "./Home1/publicity";
import intro from "./Home1/intro";
import videos from "./Home1/videos";
import charge from "./Home1/charge";
import team from "./Home1/team";
import task from "./Home1/task";
import work from "./Home1/work";
import { projectNames } from "../utils/config.js";

export default {
  mixins: [drawMixin],
  data() {
    return {
      id: 207,
      projectName:"",
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      chargeItemDisplay: false,
      noticeDisplay: false,
      order: [],
      taskCheck: [],
      todayOrderDisplay: false,
      componentKey:0,//更改 key 以强制重新渲染组件 
    };
  },
  components: {
    PagesDialog,
    giveService,
    // publicity,
    intro,
    histogram,
    videos,
    charge,
    team,
    task,
    work,
  },
  mounted() {
    document.title = '腾泰 业主'; 
    if (this.$route.query.id) {
      this.id = parseInt(this.$route.query.id);
    }
    // this.loadData();
    this.timeFn();
    this.cancelLoading();

  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
   
  watch:{
    '$route.query.id': {//监听参数的变化，如监听路由中id值的变化：
        handler(newVal,oldVal){//判断newVal有没有值监听路由变化
          if(newVal!==oldVal){
            // if( newVal && oldVal ) this.$router.go(0)//如果是跳转，则刷新
            this.id = parseInt(newVal);
            this.noticeDisplay = false
            this.chargeItemDisplay = false
            this.todayOrderDisplay = false
            this.taskCheck.length = 0//右下角 管理检查记录 清空
            this.componentKey +=1000
            this.$nextTick(()=>{
              this.loadData();
              this.$refs.pagesDialogRef.close()
            });
          }
        },
      deep: true,
      immediate: true//immediate表示在watch中首次绑定的时候，是否执行handler
    }
  },
  
  methods: {
    async loadData() {
      this.projectName = projectNames.get(this.id)
      const res = await getOrderAndTask({ deptId: this.id });
      if (res.code === 200) {
        this.order = res.data.order;
        this.taskCheck = res.data.taskCheck;
        this.todayOrder = res.data.todayOrder;
        this.todayOrderDisplay = true;
      }

      const res2 = await getNotice({ deptId: this.id });
      if (res2.code === 200) {
        if (res2.data.chargeItem) {
          this.chargeItem = res2.data.chargeItem;
          this.chargeItemDisplay = true;
        }
        if (res2.data.notice) {
          this.notice = JSON.parse(res2.data.notice.noticeContent);
          this.noticeDisplay = true;
        }
      }
    },

    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH : mm : ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    pagesDialogOpen() {
      this.$refs.pagesDialogRef.open()
    },
   
  },
};
</script>

<style lang="scss" scoped>
#index {
  color: #d3d6dd;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;
  background-image: url("https://f.jsttwy.cn/public/background2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .host-body {
    width: 100%;
    position: relative;

    .top {
      width: 100%;
      height: 100px;
      background-image: url('https://f.jsttwy.cn/public/title.png');
      background-repeat: no-repeat;
      display: flex;
      text-align: center;
      line-height: 38px;

      .top-left {
        width: 400px;
        color: #ffffff;

        .text1 {
          font-size: 14px;
        }

        .text2 {
          font-size: 16px;
          margin-left: 12px;
        }
      }

      .title {
        width: 1100px;
        font-size: 50px;
        line-height: 100px;
      }

      .top-right {
        width: 400px;
      }
    }

    .body {
      width: 100%;
      height: 950px;
      display: flex;
      padding: 20px 20px 0;
      box-sizing: border-box;

      .left {
        width: 520px;

        div {
          height: 300px;
          width: 100%;
          background-image: url("https://f.jsttwy.cn/public/Smallbox.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .box2 {
          margin: 16px 0 14px;
        }

      }

      .middle {
        width: 800px;
        margin: 0 18px;

        div {
          height: 450px;
          width: 100%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .box1 {
          background-image: url("https://f.jsttwy.cn/public/box2.png");

        }
        .box2 {
          margin-top: 34px;
          background-image: url("https://f.jsttwy.cn/public/box.png");

        }

      }

      .right {
        width: 520px;

        div {
          height: 300px;
          width: 100%;
          background-image: url("https://f.jsttwy.cn/public/Smallbox.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .box2 {
          margin: 16px 0 14px;
        }

      }
    }


  }
}
</style>
